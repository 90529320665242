export const ROUTE_LOGIN = 'login';
export const ROUTE_ADMIN = 'admin';
export const ROUTE_ACCESS_DENIED = 'access-denied';
export const ROUTE_ACCESS_DENIED_VIPER = 'access-denied-viper';
export const ROUTE_OVERVIEW = `overview`;
export const ROUTE_START = 'start';
export const ROUTE_USER = 'user';
export const ROUTE_USER_DETAILS = `${ROUTE_OVERVIEW}/details/:email`;
export const ROUTE_TENANT = 'tenant';
export const ROUTE_TENANT_DETAILS = `${ROUTE_OVERVIEW}/:name`;
export const ROUTE_CONFIGURATION = 'configurations';
export const ROUTE_DEVICE = 'device';
export const ROUTE_DEVICE_ATTRIBUTES = `${ROUTE_OVERVIEW}/attributes/:deviceId`;
export const ROUTE_DEVICE_EVENTS = `${ROUTE_OVERVIEW}/events/:deviceId`;
export const ROUTE_FAQ = `FAQ`;
export const PATH_USER_OVERVIEW = `/${ROUTE_ADMIN}/${ROUTE_USER}/${ROUTE_OVERVIEW}`;
export const PATH_TENANT_OVERVIEW = `/${ROUTE_ADMIN}/${ROUTE_TENANT}/${ROUTE_OVERVIEW}`;
export const PATH_DEVICE_OVERVIEW = `/${ROUTE_ADMIN}/${ROUTE_DEVICE}/${ROUTE_OVERVIEW}`;
export const PATH_CONFIGURATION = `/${ROUTE_ADMIN}/${ROUTE_CONFIGURATION}/${ROUTE_OVERVIEW}`;
export const ROUTE_VDS_CONFIGURATION = 'videodataselector';
export const ROUTE_SELECTOR = 'selector';
export const ROUTE_REGISTRATION = 'registration';
export const ROUTE_VDS_CONFIGURE_ENTERPRISES_AND_FLEETS = 'configure-enterprises-and-fleets';
export const ROUTE_VDS_COUNTRY_WHITELIST = 'country-whitelist';
export const ROUTE_REPORTS = 'reports';
export const ROUTE_SMOKE_CONFIGURATION = 'smoke';
export const ROUTE_DAMAGE_CONFIGURATION = 'damage';
export const PATH_VDS_CONFIGURATION_OVERVIEW = `${PATH_CONFIGURATION}/${ROUTE_VDS_CONFIGURATION}`;
export const PATH_VDS_CONFIGURATION_SELECTOR = `${PATH_CONFIGURATION}/${ROUTE_VDS_CONFIGURATION}/${ROUTE_SELECTOR}`;
export const PATH_VDS_CONFIGURATION_REGISTRATION = `${PATH_CONFIGURATION}/${ROUTE_VDS_CONFIGURATION}/${ROUTE_REGISTRATION}`;
export const PATH_CONFIGURE_ENTERPRISES_AND_FLEETS = `${PATH_CONFIGURATION}/${ROUTE_VDS_CONFIGURATION}/${ROUTE_VDS_CONFIGURE_ENTERPRISES_AND_FLEETS}`;
export const PATH_VDS_COUNTRY_WHITELIST = `${PATH_CONFIGURATION}/${ROUTE_VDS_CONFIGURATION}/${ROUTE_VDS_COUNTRY_WHITELIST}`;
export const PATH_REPORTS = `${PATH_CONFIGURATION}/${ROUTE_VDS_CONFIGURATION}/${ROUTE_REPORTS}`;
export const PATH_SMOKE_CONFIGURATION_OVERVIEW = `${PATH_CONFIGURATION}/${ROUTE_SMOKE_CONFIGURATION}`;
export const PATH_DAMAGE_CONFIGURATION_OVERVIEW = `${PATH_CONFIGURATION}/${ROUTE_DAMAGE_CONFIGURATION}`;
export const PATH_FAQ = `/${ROUTE_ADMIN}/${ROUTE_FAQ}`;
